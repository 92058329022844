<!--
 * @Descripttion:
 * @version:
 * @Author: 张圳
 * @Date: 2023-11-15 16:53:18
 * @LastEditors: wenwen
 * @LastEditTime: 2024-02-22 15:17:08
-->
<template>
  <div>
    <div class="set-box">
      <div class="set-box-title">
        <com-title>设置</com-title>
      </div>
      <div class="switch-box">
        <div>到结束时间自动结束学习计划</div>
        <div style="margin-left: 16px;"><el-switch
          v-model="dataInfo.switchValue"
          :disabled="dataInfo.status==2"
          @change="changeSwitch"
          >
        </el-switch></div>
      </div>
    </div>
  </div>
</template>

<script>
import comTitle from '@/components/comTitle/index.vue'
import { editPlanDetailApi } from '@/api/study'
export default {
  props: {
    dataInfo: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    comTitle
  },
  created () {

  },
  data () {
    return {
      switchValue: true
    }
  },
  methods: {
    changeSwitch (value) {
      if (value) {
        this.dataInfo.expiredFlag = 1
      } else {
        this.dataInfo.expiredFlag = 0
      }
      this.courseBaseInforEdit()
    },
    courseBaseInforEdit () {
      editPlanDetailApi(this.dataInfo).then(res => {
        if (res.code === 200) {
          this.$emit('uploadDetail')
        }
      })
    }
  }
}
</script>

<style lang='scss' scoped>
  .set-box{
    padding-bottom: 430px;
    margin-bottom: 18px;
  }
  .set-box-title{
    margin-bottom: 18px;
  }
  .switch-box{
    display: flex;
    align-items: center;
  }
</style>
